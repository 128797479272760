












































































































import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { MintingViewModel } from '../viewmodels/minting-viewmodel'

@Observer
@Component
export default class extends Vue {
  @Inject({}) vm!: MintingViewModel

  // history = [
  //   { date: '2022-04-30', price: '110 LCH', quantity: '110 / 1212 LCH', status: 'close' },
  //   { date: '2022-04-30', price: '110 LCH', quantity: '110 / 1212 LCH', status: 'open' },
  //   { date: '2022-04-30', price: '110 LCH', quantity: '110 / 1212 LCH', status: 'upcoming' },
  //   { date: '2022-04-30', price: '110 LCH', quantity: '110 / 1212 LCH', status: 'open' },
  //   { date: '2022-04-30', price: '110 LCH', quantity: '110 / 1212 LCH', status: 'open' },
  //   { date: '2022-04-30', price: '110 LCH', quantity: '110 / 1212 LCH', status: 'close' },
  //   { date: '2022-04-30', price: '110 LCH', quantity: '110 / 1212 LCH', status: 'open' },
  //   { date: '2022-04-30', price: '110 LCH', quantity: '110 / 1212 LCH', status: 'upcoming' },
  //   { date: '2022-04-30', price: '110 LCH', quantity: '110 / 1212 LCH', status: 'open' },
  // ]

  get width() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
      case 'sm':
        return 320
      case 'md':
        return 660
      case 'lg':
        return 907
      case 'xl':
        return 1110
    }
  }

  // page = 1

  // onPageChange(page: number) {
  //   this.page = page
  // }

  get numberItemDisplay() {
    return this.$vuetify.breakpoint.sm ? 3 : 5
  }

  // get pageNumber() {
  //   return Math.ceil(this.history.length / this.numberItemDisplay)
  // }

  // get historyDisplay() {
  //   return this.history.slice((this.page - 1) * this.numberItemDisplay, this.page * this.numberItemDisplay)
  // }

  // dialog = false
  resolve?: (value: unknown) => void

  // async open() {
  //   this.dialog = true
  //   return new Promise((resolve) => (this.resolve = resolve))
  // }

  // async cancel() {
  //   this.dialog = false
  // }
}
